import React from 'react';
import { Video } from './Video';

export const Welcome = () => {
  // return <iframe title="train" src="https://firebasestorage.googleapis.com/v0/b/trekk-fdf31.appspot.com/o/video%2FTrainJourneyTrekk2.mp4?alt=media&token=4637c3ab-5903-419d-a412-c9c4aa79a58a" />;
  return (
    <Video/>
  )
};

export default Welcome;
