export const apiKey = 'AIzaSyBFPlPrkshCO2vamg61gsby8voQwgCccuM';
export const authDomain = 'trekk-fdf31.firebaseapp.com';
export const projectId = 'trekk-fdf31';
export const storage = 'trekk-fdf31.appspot.com';

export const triposoAccount = 'IOZI4SVH';
export const triposoToken = '8c8xr8mf5fd31wdh7r5vt2vdk9f9wgez';

export const places = 'AIzaSyBFPlPrkshCO2vamg61gsby8voQwgCccuM';

